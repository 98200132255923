<template>
  <div class="main"
       v-if="detail">
    <div class="cover"
         v-if="coverList.length>0">
      <van-swipe :show-indicators="false"
                 @change="onChangeSwiper"
                 v-if="coverList.length>0">
        <van-swipe-item v-for="(item,index) in coverList"
                        :key="index">
          <template v-if="item.coverType===1">
            <img :src="item.url">
          </template>
          <template v-if="item.coverType===2">
            <VideoPlayer ref="videoPlayer"
                         :videoUrl="item.url"
                         :poster="item.coverImgUrl"
                         @play="videoPlay"></VideoPlayer>
          </template>
        </van-swipe-item>
      </van-swipe>
      <div class="cover-count"
           v-if="indicatorsVisible">{{currentIndex+1}}/{{coverList.length}}</div>
    </div>
    <div class="selection">
      <div class="name">{{detail.ruleDetails.traceGoodsName}}</div>
      <div class="title">{{detail.goodsDetails.product.mainIngredient}}</div>
      <div class="content">
        <p style="color:#999;margin:0">感谢您使用{{detail.ruleDetails.traceGoodsName}}产品！</p>
        <p style="color:#999;margin:0">农药产品追溯验证平台技术由老刀网络提供，获取更多信息请登录<a href="http://www.cfc88.cn">www.cfc88.cn</a>平台</p>
      </div>
    </div>
    <div class="selection">
      <div class="title">产品信息</div>
      <div class="content">
        <p style="margin:0">登记证持有人</p>
        <p style="margin:0">{{detail.goodsDetails.holderList.length>0?detail.goodsDetails.holderList[0].entName:''}}</p>
        <p class="m-t-15">单元识别码</p>
        <p style="margin:0">{{detail.code}}</p>
      </div>
      <div class="content">
        <p>产品名称:{{detail.ruleDetails.traceGoodsName}}</p>
        <p>农药名称:{{detail.ruleDetails.genericName}}</p>
        <p>登记证号:{{detail.goodsDetails.product.registerSerial}}</p>
        <p>产品规格:{{detail.ruleDetails.traceSpecDesc}}</p>
      </div>
    </div>
    <div class="selection">
      <div class="title">生产信息</div>
      <div class="content">
        <p>生产日期:<span style="color:#0652DD">{{detail.ruleDetails.produceDateType===1?'见包装喷码':detail.ruleDetails.produceDate}}</span></p>
        <p>生产批次:<span style="color:#0652DD">{{detail.ruleDetails.produceSerialType===1?'见包装喷码':detail.ruleDetails.produceDate}}</span></p>
        <p>质量检验:<span style="color:#0652DD">合格</span></p>
      </div>
    </div>
    <div class="selection">
      <div class="title">验证信息</div>
      <div class="content">
        <p>查询次数:{{detail.queryCount}}</p>
        <p>首次查询:{{detail.queryRecord.firstTime}}</p>
        <!-- <p>本次由<span style="color:#0652DD">{{detail.ruleDetails.traceGoodsName}}</span>持有人查询。</p> -->
        <p>查询时间:<span style="color:#0652DD">{{detail.queryRecord.queryTime}}</span></p>
        <p>本次为第<span style="color:#0652DD">{{detail.queryCount}}</span>次查询,您查询的产品是<span style="color:#0652DD">{{detail.ruleDetails.productEntName}}</span>生产，请放心使用。</p>
      </div>
    </div>
    <!-- <div class="selection">
      <div class="title">产品流向</div>
      <div class="content">
        <p></p>
      </div>
    </div> -->
  </div>
</template>

<script>
import VideoPlayer from '../../components/common/VideoPlayer'
import { getYardsDetail } from '../../services/yardsapi';
export default {
  components: { VideoPlayer },
  data () {
    return {
      code: '',
      lat: '',
      lon: '',
      detail: null,
      coverList: [],
      currentIndex: 0,
      indicatorsVisible: true
    }
  },
  watch: {
    code: {
      handler () {
        this.initMap();
      },
      deep: true,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.code = this.$route.query.code;
      // this.initMap()
    },
    onChangeSwiper (e) {
      console.log(e);
      if (this.currentIndex === e) return
      this.currentIndex = e
      this.indicatorsVisible = true
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer[0].player.pause()
      }
    },
    videoPlay () {
      this.indicatorsVisible = false
    },
    initMap () {
      let _this = this;
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 5000,
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);
        // data是具体的定位信息
        function onComplete (data) {
          console.log(data);
          _this.lat = data.position.lat;
          _this.lon = data.position.lng;
          _this.getYardsDetail()
        }
        function onError (data) {
          console.log(data);
          _this.getYardsDetail()
        }
      })
    },
    getYardsDetail () {
      let params = {}
      if (this.lat && this.lon) {
        params.lat = this.lat
        params.lon = this.lon
      }
      getYardsDetail(this.code, params).then(res => {
        if (res.code === 0) {
          this.detail = res.data
          let imgArr = this.detail.goodsDetails.coverList.filter(item => item.coverType === 1)
          let videoArr = this.detail.goodsDetails.coverList.filter(item => item.coverType === 2)
          this.coverList = videoArr.concat(imgArr)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  max-width: 750px;
  margin: 0 auto;
  background-color: #f7f9fd;
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    font-size: 15px;
    line-height: 44px;
    text-align: center;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .cover {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .cover-count {
      position: absolute;
      right: 12px;
      bottom: 12px;
      padding: 0 10px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .selection {
    background-color: #fff;
    padding: 10px 15px;
    margin-bottom: 12px;
    p {
      font-size: 14px;
      line-height: 2;
      &:not(:first-child) {
        margin-top: 7px;
      }
    }
  }
  .name {
    font-size: 17px;
  }
  .title {
    font-size: 16px;
    line-height: 40px;
    border-bottom: 1px solid #dedede;
  }
  .content {
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #dedede;
    }
  }
}
</style>